<template>
  <div class="cross-detect">
    <div class="top">
      <div class="container width-percent80 line-center">
        <h2 class="title">
          <img src="../../assets/cross_detect.png" /><span>智荟网跨库检索</span>
        </h2>
        <ul class="industry width-percent80 line-center">
          <li class="lists" @click="industrySearch(item)" v-for="(item, index) in industryData" :key="index">
            <span>{{ item }}</span>
          </li>
        </ul>
        <div class="search-area center">
          <el-input v-model="valueData" placeholder="请输入关键词" @keyup.enter.native="handleIconClick(1)">
            <i class="el-input__icon el-icon-search" slot="suffix" style="cursor: pointer" @click="handleIconClick(1)">
            </i>
          </el-input>

          <el-radio-group class="radio" v-model="radio" @change="clickRadioSearch">
            <el-radio v-for="(item, index) in radioData" :key="index" :label="index">{{ item.title }}</el-radio>
          </el-radio-group>

          <div v-show="showOfDisk" class="margin-t-30">
            <span class="text-white">
              已收录 <font color="">{{ radioData[selectNum].num }} 条数据</font>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-result2" v-show="searchResult2">
      <img :src="response_img" style="width: 100%" />
    </div>
    <div class="search-result line-center width-percent80">
      <div class="body listshow1" ref="bodyData" v-show="searchShows">
        <!-- 文章显示区域 -->
        <div ref="from" v-loading="loading" class="show-area line-center width-percent80">
          <!-- 文章显示区域 -->
          <!-- 期刊文献 -->
          <div v-if="radio == 0 && radioFlag == 0 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData0" :key="index">
              <dt v-html="item.title"></dt>
              <dd class="show-lists">
                <img :src="item.img" />
                <p v-html="item.abstract"></p>
              </dd>
              <a @click="downPdf(item.downloadUrl) && item.flag" :class="{
                noDtl: item.articleright == '暂无详情' ? true : false,
                noDtl2: item.articleright == '下载详情' ? true : false,
              }">
                {{ item.articleright }}
              </a>
            </dl>
          </div>
          <!-- 专业词条 -->
          <div v-show="radio == 1 && radioFlag == 1 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData1" :key="index">
              <dt v-html="item.Title" style="font-weight:bold;"></dt>
              <dd class="show-lists">
                <p v-html="item.DescribeText" style="margin-bottom:10px;"></p>
                <div v-if="item.machined_keywords">
                  <span>词条：</span>
                  <span style="margin-right: 10px;" v-for="(val,i) of item.machined_keywords?JSON.parse(item.machined_keywords):[]" :key="'ct'+i">{{ val }}</span>
                </div>
              </dd>
              <span class="noDtl pointer" @click="goToArticleDetailUrl(item.DetailUrl)">
                详情：{{ item.DetailUrl}}
              </span>
            </dl>
          </div>

          <!-- 专利库 -->
          <div v-if="radio == 2 && radioFlag == 2 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData2" :key="index">
              <dt v-html="item.tio"></dt>
              <p>{{ item.abo }}</p>
              <p>申请人：{{ item.apOr||'暂无'}}</p>
              <p>发明(设计)人：{{ item.inOr||'暂无'}}</p>
              <p>申请号：{{ item.an||'无'}}</p>
              <p>申请时间：{{ item.ad?(item.ad.slice(0,4)+'-'+item.ad.slice(4,6)+'-'+item.ad.slice(6)):'无'}}</p>
            </dl>
          </div>

          <!-- 知识头条 -->
          <div v-if="radio == 3 && radioFlag == 3 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData3" :key="index">
              <dt v-html="item.title"></dt>
              <dd class="show-lists">
                <img :src="item.coverImg" />
                <p v-html="item.content"></p>
              </dd>
              <span class="noDtl pointer" @click="goToArticleDetail(item, 1)">
                详情：{{ 'http://www.izhihui.net/articleDetail?id=' + item.id }}
              </span>
            </dl>
          </div>
          <!-- 智能问答 -->
          <div v-if="radio == 4 && radioFlag == 4 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData4" :key="index">
              <dt v-html="item.questionTitle"></dt>
              <dd class="show-lists">
                <img :src="item.img" />
                <p v-html="item.questionContent"></p>
              </dd>
              <span class="noDtl pointer" @click="goTowriteanswerDetail(item, 2)">
                详情：{{ 'http://www.izhihui.net/writeanswer?id=' + item.id }}
              </span>
            </dl>
          </div>
          <!-- 全球产品 -->
          <div v-if="radio == 5 && radioFlag == 5 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData5" :key="index">
              <dt v-html="item.Title"></dt>
              <dd class="show-lists">
                <img :src="item.DescribeImage" />
                <p v-html="item.DescribeText"></p>
              </dd>
              <a :href="item.DetailUrl" target="_blank">详情：{{ item.DetailUrl }}</a>
            </dl>
          </div>
          <!-- isli资源库 -->
          <div v-if="radio == 6 && radioFlag == 6 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData6" :key="index">
              <dt v-html="item.isliTitle"></dt>
              <dt>作者：{{ item.isliAuthor }}</dt>
              <dd class="show-lists">
                <p v-html="item.remark_c"></p>
              </dd>
            </dl>
          </div>

          <!-- 会议论文 -->
          <div v-if="radio == 7 && radioFlag == 7 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData7" :key="index">
              <dt v-html="item.标题"></dt>
              <dt>论文作者：{{ item.论文作者 }}</dt>
              详情：<a :href="item.链接" target="_blank" v-html="item.url"></a>
            </dl>
          </div>

          <!-- 学位论文 -->
          <div v-if="radio == 8 && radioFlag == 8 ? true : false">
            <dl class="show-list" v-for="(item, index) in respData8" :key="index">
              <dt v-html="item.标题"></dt>
              <dt>责任者：{{ item.责任者 }}</dt>
              详情：<a :href="item.链接" target="_blank" v-html="item.url"></a>
            </dl>
          </div>
        </div>

        <!-- 按钮区域 -->
        <div v-show="respTotal > 0 && respTotal2 > 0 ? true : false">
          <div class="foot width-percent80 line-center" v-show="radioBrother == radio ? true : false">
            <el-pagination background @current-change="handleCurrentPageChange" layout="prev, pager, next"
              :page-size="pageSize" :page-count="page_count">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
var instance = axios.create({
  timeout: 60000
})
export default {
  data() {
    return {
      valueData: "", // 输入的搜索信息
      radio: 0, // 单选框checked
      radioFlag: 0, // 控制没有发送请求的时候的内容展示框显隐
      totalNum: 0,
      showOfDisk: false,
      industryData: [
        "计算机科学",
        "材料科学",
        "电工电子",
        "教育学",
        "图书情报",
        "合成生物学",
        "人工智能",
        "机械工程",
        "物理学",
        "海洋科学",
        "大气科学",
        "地理科学",
        "网络经济",
        "生物经济",
        "绿色低碳",
        "数字创意",
        "高端制造",
        "新材料",
      ], // 领域
      radioData: [
        { title: "期刊文献库", num: 71101407 },
        { title: "专业词条库", num: 6438941 },
        { title: "专利库", num: 110862336 },
        { title: "知识头条", num: 11650 },
        { title: "智能问答", num: 3769 },
        { title: "全球产品库", num: 5038617 },   //请求无返回
        { title: "isli资源查询库", num: 1361204 },
        { title: "学位论文", num: 298324 },
        { title: "会议文献", num: 158116 },
      ],
      selectNum: 0,
      // 搜索结果数据
      // 期刊文献
      respData0: [{ title: "", abstract: "" }],
      // 专业词条
      respData1: [
        { Title: "", DescribeText: "", DescribeImage: "", DetailUrl: "" },
      ],
      // 专利库
      respData2: [{ title: "", describeText: "", describeImage: "" }],
      // 知识头条
      respData3: [{ title: "", coverImg: "", content: "" }],
      // 智能问答
      respData4: [{ questionTitle: "", questionContent: "" }],
      // 全球产品库
      respData5: [],
      //isli资源查询库
      respData6: [],

      //会议文献
      respData7: [],

      //学位论文
      respData8: [],

      pageSize: 10, // 每页默认显示10条数据
      currentPage: 1, // 当前页
      page_count: 0, // 总页数默认为0
      loading: false,
      searchShows: false, // 搜索后显示结果，默认关闭
      initDiv: true,
      radioBrother: 0, //按钮显示框 与radio默认值同步
      respTotal: 0, // 返回的搜索数据总条数，默认值 0
      respTotal2: 0, // 返回的数据 rows 中的 length
      searchResult2: false, // 搜索无结果提示框
      response_img: "", // 超时或无结果是提示图片
    };
  },
  watch: {
    valueData: function (newVal, oldVal) {
      if (newVal != oldVal) this.showOfDisk = false;
    },
    radio: function (newVal, oldVal) {
      if (newVal != oldVal) this.showOfDisk = false;
    },
  },
  methods: {
    // 点击领域搜索
    industrySearch(value) {
      this.valueData = value;
      this.searchShows = true;
      this.loading = true;

      this.requireApi(value);
    },

    // 点击radio搜索
    clickRadioSearch(e) {
      this.selectNum = e
      this.radio = e
      this.handleIconClick();
    },

    async handleIconClick(num) {
      if (!this.valueData != "") {
        this.$message.warning("请输入搜索内容!");
        return;
      }
      this.searchShows = true;
      this.loading = true;
      setTimeout(this.requireApi(), 0);
    },

    // 当前页发生变化
    handleCurrentPageChange(val) {
      this.currentPage = val;
      if (!this.valueData != "") {
        this.$message.warning({ message: "请输入搜索内容!" });
        return;
      }
      this.searchShows = true;
      this.loading = true;
      this.requireApi();
    },

    requireApi(value) {
      this.searchResult2 = false;
      const formData = new FormData(); //为XHR传参提供便利
      formData.append("keywords", this.valueData || value || '');
      formData.append("page", this.currentPage);
      formData.append("count", this.pageSize);
      formData.append("type", this.radio >= 6 ? this.radio + 1 : this.radio);

      instance
        .post(this.api.LoginURL.concat("/common/unifiedSearch"), formData)
        .then((res) => {
          this.radioFlag = this.radio; // 赋值给未发送请求的内容展示框类型值
          let temp_tip = document.getElementsByClassName("search-result2");
          if (res.data.success) {
            if (res.data.data == null || res.data.data.total == 0) {
              this.searchResult2 = true;
              this.searchShows = false;
              this.showResultNoneImgsrc();
              this.showOfDisk = false;
              return;
            }
            this.page_count = Math.ceil(res.data.data.total / 10.0)||1;
            let data = res.data.data.rows;
            this.respTotal = res.data.data.total||1;
            this.respTotal2 = res.data.data.rows.length;
            this.totalNum = res.data.data.totalNum;

            if (this.radio == 6) {
              this.totalNum = res.data.data.total;
            }
            if (this.radio == 7 || this.radio == 8) {
              data = res.data.data.results;
              this.page_count = res.data.data.pageCount;
              this.respTotal = res.data.data.maxCount;
              this.respTotal2 = res.data.data.rows;
              this.totalNum = res.data.data.maxCount;
            }
            this.saveRespResult(this.radio, data);
          } else {
            // 连接超时
            this.searchResult2 = true;
            this.searchShows = false;
            this.showTimeoutImgsrc();
            this.respTotal = 0; // 没返回搜索结果条数 赋0
            this.searchShows = false;
            this.loading = false;
            this.initDiv = false;
            this.radioBrother = this.radio; // this.radioBrother =  type?
            this.showOfDisk = false;
          }
        })
        .catch((error) => {
          console.log(error, 456);
          // 连接超时
          this.searchResult2 = true;
          this.searchShows = false;
          this.showTimeoutImgsrc();
          this.respTotal = 0; // 没返回搜索结果条数 赋0
          this.searchShows = false;
          this.loading = false;
          this.initDiv = false;
          this.radioBrother = this.radio;
          this.showOfDisk = false;

        });
    },

    clReplace(value) {
      try {
        return value.replace(reger1, '<font color="red">' + this.valueData + "</font>")
      } catch (error) {
        return value
      }
    },

    // 处理返回数据
    saveRespResult(type, data) {
      let reger1 = new RegExp(this.valueData, "gm");
      if (type == 0) {
        this.respData0 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData0.push({
            title: this.clReplace(data[i].title),
            abstract: this.clReplace(data[i].abstract),
            articleright:
              data[i].downloadUrl == undefined ? "暂无详情" : "下载详情",
            downloadUrl:
              data[i].downloadUrl == undefined ? "" : data[i].downloadUrl,
            flag: data[i].downloadUrl == undefined ? false : true,
            id: data[i].id,
          });
        }
      } else if (type == 1) {
        this.respData1 = [];
        
        for (let i = 0; i < data.length; i++) {
          let val = data[i].intro;
          try {
            val = JSON.parse(val)[0];
          } catch (error) {}

          this.respData1.push({
            Title: this.clReplace(data[i].title),
            DescribeText: this.clReplace(val),
            machined_keywords: data[i].machined_keywords,
            DetailUrl:this.api.LoginURL.concat(`/resources/trading/upload/wikientity/${data[i].title}.html`)
          });

        }
      } else if (type == 2) {
        this.respData2 = data;
      } else if (type == 3) {
        this.respData3 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData3.push({
            title: this.clReplace(data[i].title),
            content: data[i].content ? data[i].content
              .replace(/<\/?.+?>/g, "")
              .replace(/&nbsp;/g, " ")
              .replace(
                reger1,
                '<font color="red">' + this.valueData + "</font>"
              ) : '',
            coverImg: data[i].coverImg.indexOf('http') > -1 ? data[i].coverImg : this.api.LoginURL.concat(data[i].coverImg),
            id: data[i].id,
          });
        }
      } else if (type == 4) {
        this.respData4 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData4.push({
            questionTitle: this.clReplace(data[i].questionTitle),
            questionContent: this.clReplace(data[i].questionContent),
            id: data[i].id,
          });
        }
      } else if (type == 5) {
        this.respData5 = [];
        for (let i = 0; i < data.length; i++) {
          this.respData5.push({
            DescribeImage: data[i].DescribeImage,
            DescribeText: this.clReplace(data[i].DescribeText),
            Title: this.clReplace(data[i].Title),
            DetailUrl: data[i].DetailUrl,
          });
        }
      } else if (type == 6) {
        this.respData6 = [];
        for (let i = 0; i < data.length; i++) {
          let title = ''
          let isliData = data[i].isliData

          // console.log(data[i]);
          // if(i==8){
          // }
          // if(!title&&isliData){
          try {
            title = JSON.parse(isliData).isli_content.remark_c
            // if(i==8){
            //   console.log(JSON.parse(isliData));
            // }
            if (!title) {
              title = JSON.parse(isliData).isli_content
            }
          } catch (error) {
            console.log(error);
          }
          // }
          // console.log(data[i]);
          this.respData6.push({
            isliTitle: this.clReplace(data[i].isliTitle),
            isliAuthor: data[i].isliAuthor,
            remark_c: this.clReplace(title),
            isliId: data[i].isliId,
          });
        }
      } else if (type == 7) {
        this.respData7 = [];
        for (let item of data) {
          this.respData7.push({
            所属会议: item.所属会议,
            标题: this.clReplace(item.标题),
            类型: item.类型,
            论文作者: this.clReplace(item.论文作者),
            链接: item.链接,
            url: this.clReplace(item.链接),
          });
        }
      } else {
        this.respData8 = [];
        for (let item of data) {
          this.respData8.push({
            学科分类: item.学科分类,
            标题: this.clReplace(item.标题),
            类型: item.类型,
            责任者: this.clReplace(item.责任者),
            论文所属学校: item.论文所属学校,
            链接: item.链接,
            url: this.clReplace(item.链接),
          });
        }
      }
      this.loading = false;
      this.initDiv = false;
      this.radioBrother = type;
      this.showOfDisk = true;
    },

    downPdf(url) {
      if (url != "") {
        let realUrl = url.split('["')[1].split('"]')[0];
        window.open(realUrl, "_self");
      }
    },
    showTimeoutImgsrc() {
      this.response_img = require("../../assets/connection_out_time.png");
    },
    showResultNoneImgsrc() {
      this.response_img = require("../../assets/result_none.png");
    },

    goToArticleDetail(item, type) {
      let a = document.createElement('a')
      a.href = 'http://www.izhihui.net/articleDetail?id=' + item.id
      a.target = '_blank'
      a.click()
    },

    goToArticleDetailUrl(url) {
      let a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.click()
    },


    // 智能问答详情
    goTowriteanswerDetail(item, type) {
      let a = document.createElement('a')
      a.href = 'http://www.izhihui.net/writeanswer?id=' + item.id
      a.target = '_blank'
      a.click()
    },
  },
};
</script>
<style scoped>
/* public style */
body {
  margin: 0;
  padding: 0;
}

body div {
  box-sizing: border-box;
}

h2 {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.width-percent80 {
  width: 80%;
}

.line-center {
  margin: 0 auto;
}

.center {
  text-align: center;
}

ul {
  padding-inline-start: 0;
}

.text-white {
  color: #fff;
  font-size: 14px;
}

/* private style */
.title {
  color: #f9fafb;
  letter-spacing: 10px;
}

.title span {
  vertical-align: 10px;
  margin-left: 11px;
  font-size: 0.169118rem;
}

.title:hover {
  color: #f9fafb;
}

.cross-detect {
  box-sizing: border-box;
}

.top {
  /* background: #f7f7f7; */
  background-image: url("~@/assets/cross_detect_bkgd.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* font-size: 0; */
}

.industry {
  padding-top: 50px;
  padding-bottom: 50px;
}

.container {}

.show-area {
  background: white;
  min-height: 50px;
}

.bounder {
  height: 20px;
  background-color: #6b8dcd;
}

.lists {
  float: left;
  width: 15%;
  border: 1px solid #76bdff;
  text-align: center;
  color: #0463bd;
  height: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
  background-color: white;
  cursor: pointer;
}

.lists:not(:nth-child(6n + 1)) {
  margin-left: 14px;
}

.industry {
  margin-bottom: 30px;
  margin-top: 30px;
}

.industry::after {
  display: block;
  content: "";
  clear: both;
}

.search-area {
  padding-bottom: 50px;
}

.show-area {
  padding-left: 20px;
}

.radio {
  margin-top: 50px;
  /* margin-right: -100px; */
  display: flex;
  justify-content: space-between;
}

.show-list {
  border-bottom: 1px dashed #949494;
  padding-bottom: 15px;
}

.show-list .show-lists {
  margin-inline-start: 0px;
}

.show-list img {
  /* float: left; */
  vertical-align: top;
  max-width: 150px;
  height: auto;
  margin-right: 10px;
}

.show-list dt {
  font-size: 16px;
  line-height: 49px;
  letter-spacing: 2px;
  /* word-spacing: normal; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 49px;
  white-space: nowrap;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

.show-lists p {
  display: inline-block;
  font-size: 13px;
  word-break: break-all;
  width: 79%;
  height: 95px;
  /* background: #79797f; */
  line-height: 24px;
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif;
  letter-spacing: 1px;
  margin-block-start: 0;
  margin-block-end: 0;
  overflow: hidden;
}

.foot {
  background-color: #f5f5f7;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  width: 90%;
}

.search-result,
.foot {
  /* margin-top: 50px; */
}

.initDiv {
  height: 300px;
}

.noDtl {
  color: #333;
}

.noDtl2 {
  color: #333;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.margin-t-30 {
  margin-top: 30px;
}

/* elementUI reset style*/
.search-area>>>.el-input__inner {
  width: 80%;
}

.el-icon-search {
  color: #2283f9;
  font-size: 28px;
}

.search-area>>>.el-input__suffix {
  width: 25%;
}

.radio>>>.el-radio {
  color: white;
  cursor: pointer;
  /* margin-right: 103px; */
}

.radio>>>.el-radio__input.is-checked+.el-radio__label {
  color: white;
}

.foot>>>.el-pagination.is-background .el-pager li {
  margin: 0 23px;

  color: #606266;
  min-width: 39px;
  border-radius: 2px;
  height: 39px;
  line-height: 39px;
  box-sizing: border-box;
  text-align: center;
}

.foot>>>.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #409eff;
}

.foot>>>.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff;
  color: #fff;
  width: 39px;
  height: 39px;
  line-height: 39px;
  border: 0;
  border-radius: 0 !important;
}

.foot>>>.el-pagination.is-background .btn-next,
.foot>>>.el-pagination.is-background .btn-prev {
  margin: 0 25px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 39px;
  height: 39px;
  line-height: 39px;
}
</style>
